import { Stack } from "@chakra-ui/layout"
import AppContainer from "src/common/AppContainer"
import { useAccent3 } from "src/modeAwareColors"
import { H1, H2 } from "src/typography/typography"

function LargeCta({
  title,
  subtitle,
  renderBottom,
  ...rest
}) {
  const a3 = useAccent3()

  return (
    <AppContainer
      textAlign='center'
      pt={28}
      pb={32}
      {...rest}
    >
      <Stack
        spacing='base'
        alignItems='center'
      >
        <H1
          as='h2'
        >
          {title}
        </H1>
        <H2
          as='p'
          color={a3}
          maxW='549px'
        >
          {subtitle}
        </H2>
        <Stack
          direction='row'
          spacing={[10, 10, 20]}
          justify='center'
        >
          {renderBottom()}
        </Stack>
      </Stack>
    </AppContainer>
  )
}

export default LargeCta
