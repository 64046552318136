import { chakra } from "@chakra-ui/system"
import { NextHyperlink } from "src/typography/typography"

const GoLink = ({ children, href, ...rest }) => {
  return (
    <NextHyperlink
      href={href}
      role='group'
      position='relative'
      {...rest}
    >
      <chakra.span
        pr={2}
      >
        {children}
      </chakra.span>
      <chakra.span
        position='absolute'
        transform='translateX(0)'
        transition='.3s all'
        _groupHover={{
          transform: "translateX(8px)",
          transition: '.3s all',
        }}
        _hover={{
          transform: "translateX(8px)",
          transition: '.3s all',
        }}>
        {`→`}
      </chakra.span>
    </NextHyperlink>
  )
}

export default GoLink
