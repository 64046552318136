import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/layout"
import { chakra } from "@chakra-ui/system"
import AppContainer from "src/common/AppContainer"
import GoLink from "src/common/GoLink"
import HeadingGradient from "src/common/HeadingGradient"
import NextImage from "src/common/NextImage"
import GoalsIllustration from "src/marketing/goalsIllustration"
import Hero from "src/marketing/hero"
import LargeCTA from 'src/marketing/largeCta'
import { HowDoesItWorkLink, SignupLink } from "src/marketing/links"
import { useAccent3, useAccent5, useBackground, useForeground } from "src/modeAwareColors"
import { routes } from "src/navigation/util"
import { H2, H3 } from "src/typography/typography"

const TwoColumnRow = ({ left, right, ...rest }) => {
  return (
    <AppContainer py={28} {...rest}>
      <Stack
        direction={['column', 'column', 'row']}
        justify='center'
        align='center'
      >
        <Box w={['100%', '100%', '50%']}>
          {left}
        </Box>
        <Box w={['100%', '100%', '50%']}>
          {right}
        </Box>
      </Stack>
    </AppContainer>
  )
}

const SecondaryCta = () => {
  const fg = useForeground()
  const bg = useBackground()
  const a5 = useAccent5()

  return (
    <TwoColumnRow
      bg={fg}
      left={(
        <Flex
          justify='center'
        >
          <NextImage
            src='/images/projectlink-searching.svg'
            dimensions={[192, 206]}
          />
        </Flex>
      )}
      right={(
        <Stack
          spacing='base'
        // alignItems='center'
        >
          <Heading
            as='h2'
            textStyle='h2'
            color={bg}
          >
            Unlock business
          </Heading>
          <Text
            textStyle='h3'
            color={a5}
            maxW='308px'
          >
            Projects sourced from around the globe.<br />New projects added all the time.
          </Text>
        </Stack>
      )}
    />

  )
}

const TertiaryCta = () => {
  const a3 = useAccent3()

  return (
    <TwoColumnRow
      left={(
        <Stack
          spacing='base'
          pl={[0, 0, 28]}
        >
          <H2>
            Simplify your<br />research and sales
          </H2>
          <H3
            color={a3}
          >
            Access the fastest growing<br />heavy industry database for projects and companies
          </H3>
        </Stack>
      )}
      right={(
        <Flex
          justify='center'
          display={['none', 'none', 'flex']}
        >
          <NextImage
            src='/images/projectlink-invest.svg'
            dimensions={[289, 179]}
          />
        </Flex>
      )}
    />
  )
}

const QuatraryCta = () => {
  const a3 = useAccent3()

  return (
    <TwoColumnRow
      pt={[0, 0, 28]}
      left={(
        <Flex
          justify='center'
          pb={['base', 'base', 0]}
        >
          <GoalsIllustration />
        </Flex>
      )}
      right={(
        <Stack spacing='base'>
          <H3>
            <chakra.span fontWeight={700}>
              {`Supported. `}
            </chakra.span>
            <chakra.span
              color={a3}
            >Our team works with you</chakra.span>
          </H3>
          <H3>
            <chakra.span fontWeight={700}>
              {`Secure. `}
            </chakra.span>
            <chakra.span
              color={a3}
            >Industry leading infrastructure</chakra.span>
          </H3>
          <H3>
            <chakra.span fontWeight={700}>
              {`Private. `}
            </chakra.span>
            <chakra.span
              color={a3}
            >You own and control your data</chakra.span>
          </H3>
          <Stack
            direction='row'
            spacing={[10, 10, 20]}
          >
            <HowDoesItWorkLink />
            <SignupLink />
          </Stack>
        </Stack>
      )}
    />
  )
}

const Index = () => {
  return (
    <>
      <Hero>
        <Heading
          as='h1'
          textStyle='headline'
        >
          Connecting projects<br />to
          <HeadingGradient start='highlight.pink' end='#FF00C7'> builders </HeadingGradient>
          and
          <HeadingGradient start='#21F1C2' end='#00E0FF'> suppliers </HeadingGradient>
        </Heading>
        <Stack
          direction='row'
          spacing={[10, 10, 20]}
        >
          <GoLink
            href={routes.newProject}
            ml='-32px'
          >
            Post a project
          </GoLink>
          <GoLink
            href={routes.explore}
          >
            Find a project
          </GoLink>
          {/* <SignupLink ml='-24px' /> */}
          {/* <HowDoesItWorkLink /> */}
        </Stack>
      </Hero>
      <LargeCTA
        title='Free leads'
        subtitle='Immediately access up-to-date project leads in your industry'
        renderBottom={() => (
          <GoLink
            href={routes.explore}
            ml='-16px'
          >
            Find a lead
          </GoLink>)}
        pb={0}
      />
      <LargeCTA
        title='Post for free'
        subtitle='Post your project requirements and reach thousands of companies'
        renderBottom={() => (
          <GoLink
            href={routes.newProject}
            ml='-16px'
          >
            Post a project
          </GoLink>)}
      />
      <SecondaryCta />
      <TertiaryCta />
      <QuatraryCta />
    </>
  )
}

export default Index
