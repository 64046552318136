import { chakra, ChakraProps } from '@chakra-ui/react'
import Image from 'next/image'

type NextImageProps = Parameters<typeof Image>[0]

export type ImageProps = Omit<ChakraProps, 'width' | 'height'> &
  Omit<NextImageProps, 'width' | 'height'> & {
    dimensions?: [number, number]
    layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive'
  }

export const NextImage: React.FC<ImageProps> = chakra(
  ({ dimensions, ...props }) => {
    return <Image {...props} width={dimensions?.[0]} height={dimensions?.[1]} />
  }
)

export default NextImage
