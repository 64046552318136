import { Box, Flex, Stack, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import FadeInOnMount from 'src/common/FadeInOnMount'
import NextImage from 'src/common/NextImage'
import { useAccent2, useBackground } from 'src/modeAwareColors'

const Hero = ({ children, ...rest }) => {
  const dotColor = useColorModeValue('#D2D2D2', 'dark.accent2')
  const bg = useBackground()
  const a2 = useAccent2()

  return (
    <Box
      bgGradient={`radial(${dotColor}, ${dotColor} 1px, ${bg} 1px, ${bg})`}
      bgSize='30px 30px'
      bgPos='0 0'
      borderBottomWidth='1px'
      borderBottomColor={a2}
      {...rest}
    >
      <Box
        bgGradient={`linear(transparent, ${bg})`}
      >
        <FadeInOnMount zIndex={9}>
          <Stack
            layerStyle='appContainer'
            align='center'
            justify='center'
            animation='2s ease-out slideIn'
            textAlign='center'
            pt={32}
            pb={36}
            spacing='base'
          >
            {children}
          </Stack>
        </FadeInOnMount>
        <Flex
          layerStyle='appContainer'
          position='relative'
          align='center'
          maxW={['400px', '440px', '525px']}
          h={['300px', '320px', '430px']}
        >
          <FadeInOnMount>
            <NextImage
              objectFit='contain'
              layout='fill'
              src='/images/projectlink-notification.png'
            />
          </FadeInOnMount>
        </Flex>
      </Box>
    </Box >
  )
}

export default Hero
