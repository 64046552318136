import GoLink from "src/common/GoLink"
import { routes } from "src/navigation/util"

const HowDoesItWorkLink = (props) => {
  return (
    <GoLink href={routes.howItWorks} {...props}>
      How does it work?
    </GoLink>
  )
}

const SignupLink = (props) => {
  return (
    <GoLink href={routes.signup} {...props}>
      Sign up
    </GoLink>
  )
}

const ContactUsLink = (props) => {
  return (
    <GoLink href={routes.contact} {...props}>
      Contact us
    </GoLink>
  )
}

export {
  HowDoesItWorkLink,
  SignupLink,
  ContactUsLink,
}
