import NextImage from "src/common/NextImage";

function GoalsIllustration() {
  return (
    <NextImage
      src='/images/projectlink-goals.svg'
      dimensions={[338, 187]}
    />
  )
}

export default GoalsIllustration
