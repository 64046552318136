import { chakra } from "@chakra-ui/react"

function HeadingGradient({ start, end, ...rest }) {
  return (
    <chakra.span
      bgGradient={`linear(to-b, ${start}, ${end})`}
      bgClip='text'
      {...rest}
    />

  )
}

export default HeadingGradient
